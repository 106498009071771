<template>
    <div>
        <div class="card mb-3" style="width: 100%" v-loading="loading" element-loading-text="Loading..."
            element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
            <div class="card-header bg-light">
                <h3 class="title fw-normal mb-0 p-2">Sharepoint Integrations</h3>
            </div>
            <el-form ref="form" v-if="getSharepointData == null">
                <el-row class="sidemenu">
                    <el-col :span="24">
                        <div class="card-body">
                            <div class="p-4 ">
                                <div class="input-container">
                                    <el-row>
                                        <el-col :span="12">
                                            <el-form-item class="main-label" label="Site URL">
                                                <el-input v-model="SharePointFormData.siteUrl"
                                                    placeholder="https://yoursiteurl.sharepoint.com"
                                                    @input="linkGenerated = false" style="width: 95%"></el-input>
                                                <div v-if="!validateSiteUrl && SharePointFormData.siteUrl !== ''"
                                                    class="validation-error">invalid SiteUrl</div>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item class="main-label" label="Site Name (case sensitive)">
                                                <el-input v-model="SharePointFormData.siteName" placeholder="MySite"
                                                    @input="linkGenerated = false" style="width: 95%"></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="8">
                                            <div class="generate-button">
                                                <el-button size="medium"
                                                    :disabled="linkGenerated || !SharePointFormData.siteUrl || !SharePointFormData.siteName || !validateSiteUrl"
                                                    type="primary" @click="generateLink">Generate
                                                    Link</el-button>
                                            </div>
                                        </el-col>
                                    </el-row>

                                </div>
                                <div v-if="linkGenerated" class="app-creation">
                                    <h6 class="fs-6  fw-medium">
                                        App creation
                                    </h6>
                                    <div class="navigation-link">
                                        <template>
                                            <span style="margin-right: 5px;"><a target="_blank" :href="appCreationUrl">{{
                                                appCreationUrl }}</a></span>
                                            <a @click="copyURL(appCreationUrl)">
                                                <i class="el-icon-copy-document"></i>
                                            </a>
                                        </template>
                                    </div>
                                    <ol>
                                        <li>Click on the following link</li>
                                        <li>Click on <b>generate key</b> for both <b> ClientId</b> and <b> ClientSecret</b>
                                            <br>
                                            (Necessarily save them for future reference)
                                        </li>
                                        <li>Add title,domain and redirect Url as follows :
                                            <ul>
                                                <li>
                                                    <b>Title</b> : eSigns (your choice)
                                                </li>
                                                <li>
                                                    <b>domain</b> : {{ domain }}
                                                    <a @click="copyURL(domain)">
                                                        <i class="el-icon-copy-document"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <b>redirect url</b> : {{ redirectUrl }}
                                                    <a @click="copyURL(redirectUrl)">
                                                        <i class="el-icon-copy-document"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>Click on create and paste the following</li>
                                    </ol>

                                    <el-row>
                                        <el-col :span="12">
                                            <el-form-item class="main-label" label="Client Id">
                                                <el-input v-model="SharePointFormData.clientId"
                                                    placeholder="Enter Your ClientId" style="width: 95%"></el-input>
                                                <div v-if="!validateClientId && SharePointFormData.clientId.trim() !== ''"
                                                    class="validation-error">invalid clientId</div>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item class="main-label" label="Client Secret">
                                                <el-input v-model="SharePointFormData.clientSecret"
                                                    placeholder="Enter Your ClientSecret" style="width: 95%"></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </div>
                                <div v-if="linkGenerated && SharePointFormData.clientId && SharePointFormData.clientSecret"
                                    class="app-permissions">
                                    <h6 class="fs-6  fw-medium">
                                        App Permissions
                                    </h6>
                                    <div class="navigation-link">
                                        <template>
                                            <span style="margin-right: 5px;"><a target="_blank" :href="appPermissionsUrl">{{
                                                appPermissionsUrl }}</a></span>
                                            <a @click="copyURL(appPermissionsUrl)">
                                                <i class="el-icon-copy-document"></i>
                                            </a>
                                        </template>
                                    </div>
                                    <ol>
                                        <li>Click on the following link</li>
                                        <li>Paste your appId(clientId) in the <b>appId</b> section and click on
                                            <b>Lookup</b> button
                                        </li>
                                        <li>Copy & paste the following in the <b>Permissions request XML box</b> :
                                            <ul>
                                                <li class="xml-code-block">{{ xmlPermissionsCode }}
                                                    <a @click="copyURL(xmlPermissionsCode)">
                                                        <i class="el-icon-copy-document"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            Then click Create button. This will redirect to you page, where we have to trust
                                            the add-in from website.
                                        </li>
                                    </ol>
                                    <el-checkbox v-model="permissionsCheckBox">I gave necessary permissions to the
                                        app</el-checkbox>
                                </div>
                            </div>
                        </div>
                    </el-col>

                </el-row>
                <div v-if="permissionsCheckBox" class="card-footer bg-white">
                    <el-form-item class="mb-0 p-2">
                        <el-button>Cancel</el-button>
                        <el-button style="background-color: #409eff; color: #ffffff" @click="onSubmit"
                            class="type-2">Save</el-button>
                    </el-form-item>
                </div>
            </el-form>
            <el-form v-else>
                <el-row class="sidemenu">
                    <el-col :span="24">
                        <div class="card-body">
                            <div class="p-4">
                                <div class="sharepoint-credentials-block">
                                    <el-row>
                                        <el-col :span="20">
                                            <h4 class="fs-6 text-dark fw-medium">
                                                Your Sharepoint Credentials
                                            </h4>
                                        </el-col>
                                        <el-col :span="4">
                                            <el-tooltip content='Delete Credentials'>
                                                <el-popconfirm title="Are you sure to delete this?" @confirm="onDelete">
                                                    <template #reference>
                                                        <el-button plain type="danger" size="small" style="height: 25px">
                                                            <i class="el-icon-delete"></i>
                                                        </el-button>
                                                    </template>
                                                </el-popconfirm>

                                            </el-tooltip>
                                        </el-col>
                                    </el-row>
                                    <el-form-item label="Site URL : ">
                                        <span v-if="applicationData.app_data && applicationData.app_data.siteUrl">{{
                                            applicationData.app_data.siteUrl }}</span>
                                        <span v-else>-</span>
                                    </el-form-item>
                                    <el-form-item label="Site Name : ">
                                        <span v-if="applicationData.app_data && applicationData.app_data.siteName">{{
                                            applicationData.app_data.siteName }}</span>
                                        <span v-else>-</span>
                                    </el-form-item>
                                    <el-row>
                                        <el-col :span="24">
                                            <el-form-item>
                                                <el-checkbox v-model="applicationData.app_settings.is_auto_download">
                                                    Automatic download to sharepoint after document completion
                                                </el-checkbox>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row v-if="applicationData.app_settings.is_auto_download">
                                        <el-col :span="20">
                                            <el-form-item label="Selected Path :">
                                                <span>Home{{ applicationData.app_settings.auto_download_folder_path
                                                }}</span>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="4">
                                            <el-form-item>
                                                <el-button size="mini" @click="getSharepointFolders('automaticDownload')">
                                                    Change Path
                                                </el-button>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row>
                                        <el-col :span="24">
                                            <el-form-item>
                                                <el-checkbox v-model="applicationData.app_settings.is_default_download">
                                                    Default download folder
                                                </el-checkbox>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row v-if="applicationData.app_settings.is_default_download">
                                        <el-col :span="20">
                                            <el-form-item label="Selected Path :">
                                                <span>Home{{ applicationData.app_settings.default_folder_path }}</span>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="4">
                                            <el-form-item>
                                                <el-button size="mini" @click="getSharepointFolders('defaultDownload')">
                                                    Change Path
                                                </el-button>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <div class="card-footer bg-white">
                    <el-form-item class="mb-0 p-2">
                        <el-button>Cancel</el-button>
                        <el-button style="background-color: #409eff; color: #ffffff" @click="onUpdate" class="type-2">Save
                            Changes</el-button>
                    </el-form-item>
                </div>
            </el-form>
            <el-dialog :visible.sync="sharepointDataDialog" title="Sharepoint Folders" draggable="true" width="55%"
                :close-on-click-modal="false">
                <el-row style="margin-bottom: 10px; ">
                    <el-col :span="18">
                        <div class="breadcrumbs">
                            <span class="breadcrumb-item" @click="handleBreadcrumbClick('')">Home</span>
                            <span v-for="(folder, index) in pathArray" :key="index">
                                <span class="breadcrumb-item" @click="handleBreadcrumbClick(folder)">{{ folder }}</span>
                                <span class="breadcrumb-separator">/</span>
                            </span>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <el-input size="small" v-model="searchQuery" placeholder="Search..." clearable
                            @clear="searchQuery = ''"></el-input>
                    </el-col>
                </el-row>
                <el-row>
                    <el-table :data="pagedSharepointData" :default-sort="{ prop: 'type', order: 'ascending' }"
                        max-height="320" border>
                        <el-table-column label="Name" prop="file" sortable>
                            <template slot-scope="scope">
                                <div v-if="scope.row.type == 'folder'" class="folder-column"
                                    @click="handlePath(scope.row.file)">
                                    <i class="el-icon-folder"></i>
                                    {{ scope.row.file }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="lastModified" label="LastModified" width="230" sortable>
                            <template slot-scope="scope">
                                {{ scope.row.lastModified }}
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="pagination-container">
                        <el-pagination @size-change="handleSharepointSizeChange" @current-change="handleCurrentPageChange"
                            :page-sizes="[5, 10, 20, 30]" :page-size="dialogTablePageSize"
                            :current-page="dialogTableCurrentPage" layout="total, sizes, prev, pager, next, jumper"
                            :total="filteredSharepointData.length">
                        </el-pagination>
                        <el-col align="right" :span="4" class="button-container">
                            <el-tooltip class="item" effect="dark" content="Select this folder as default"
                                placement="bottom">
                                <div><el-button size="mini" type="primary" @click="selectCurrentFolder()">Select this
                                        Folder</el-button></div>
                            </el-tooltip>
                        </el-col>
                    </div>
                </el-row>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import axios from "@/config/axios";
import { mapGetters } from "vuex";
import CryptoJS from 'crypto-js';
export default {
    name: 'apps-integrations',
    data() {
        return {
            linkGenerated: false,
            SharePointFormData: {
                siteUrl: '',
                siteName: '',
                clientId: '',
                clientSecret: '',
            },
            currentApp: 'Sharepoint',
            appCreationUrl: '',
            appPermissionsUrl: '',
            permissionsCheckBox: false,
            automaticDownloadCheckboxValue: false,
            defaultDownloadCheckboxValue: false,
            path: '',
            sharepointAccessToken: '',
            sharepointData: [],
            sharepointDataDialog: false,
            dialogTableCurrentPage: 1,
            dialogTablePageSize: 5,
            searchQuery: '',
            selectedAutomaticDownloadPath: '',
            selectedDefaultPath: '',
            dialogBoxType: '',
            applicationData: {
                app_settings: {
                    is_auto_download: false,
                    is_default_download: false,
                    auto_download_folder_path: "",
                    default_folder_path: "",
                },
            },
            loading: true,
            xmlPermissionsCode: `<AppPermissionRequests AllowAppOnlyPolicy="true">
		<AppPermissionRequest Scope="http://sharepoint/content/sitecollection" Right="FullControl"/>
		<AppPermissionRequest Scope="http://sharepoint/content/sitecollection/web" Right="FullControl"/>
		</AppPermissionRequests>`,
            domain: process.env.VUE_APP_SHAREPOINT_APP_INTEGRATION_DOMAIN,
            redirectUrl: process.env.VUE_APP_SHAREPOINT_APP_INTEGRATION_REDIRECT_URL

        }
    },
    methods: {
        copyURL(url) {
            navigator.clipboard.writeText(url);
            this.$message({
                message: "Copied successfully!",
                type: "success",
            });
        },
        generateLink() {
            this.appCreationUrl = `${this.SharePointFormData.siteUrl}/sites/${this.SharePointFormData.siteName}/_layouts/15/AppRegNew.aspx`;
            this.appPermissionsUrl = `${this.SharePointFormData.siteUrl}/sites/${this.SharePointFormData.siteName}/_layouts/15/AppInv.aspx`
            this.linkGenerated = true
        },
        async onSubmit() {
            try {
                this.loading=true;
                let response = await axios.post("/documents/testSharepointAccessToken", { ...this.SharePointFormData });
                if (response.data.data.access_token) {
                    let apiUrl = `${this.SharePointFormData.siteUrl}/sites/${this.SharePointFormData.siteName}/_api/Web/GetFolderByServerRelativePath(decodedurl='/sites/${this.SharePointFormData.siteName}/Shared%20Documents')?$expand=Folders,Files`;
                    await axios.get(apiUrl, {
                        headers: {
                            Authorization: `Bearer ${response.data.data.access_token}`,
                            'Accept': 'application/json',
                        },
                    });
                }
                if (this.currentApp == 'Sharepoint') {
                    let cipherText = CryptoJS.AES.encrypt(JSON.stringify(this.SharePointFormData), this.getActiveWorkspace.company_id).toString();
                    let response = await axios.post('/create-app-integration-data', { app_name: 'SHAREPOINT', app_data: cipherText })
                    if (response.data.success) {
                        this.loading=false
                        this.$notify({
                            title: 'Success',
                            message: 'Data saved Successfully',
                            type: 'success'
                        });
                        this.getApplicationData();
                    }
                    }
            }
            catch (err) {
                this.loading=false;
                this.$notify.error({
                    title: "Error",
                    message: "Make sure you have given correct credentials and permissions"
                })
            }
        },
        async onUpdate() {
            try {
                this.loading=true;
                if (this.currentApp == 'Sharepoint') {
                    await axios.patch('/update-app-integration-data', this.applicationData.app_settings);
                    this.loading=false;
                    this.$notify({
                        title: 'Success',
                        message: 'Data Updated Successfully',
                        type: 'success'
                    });
                }
            }
            catch (err) {
                this.errorNotify("Error in updating settings");
            }
        },
        async onDelete() {
            this.loading=true;
            let response = await axios.delete('/delete-app-integration-data');
            this.loading=false
            if (response.data.success) {
                this.$notify({
                    title: 'Success',
                    message: 'Data deleted Successfully',
                    type: 'success'
                });
                this.linkGenerated = false;
                this.SharePointFormData = {
                    siteUrl: '',
                    siteName: '',
                    clientId: '',
                    clientSecret: '',
                }
                this.permissionsCheckBox=false
                this.getApplicationData();

            }
        },
        async getSharepointFolders(type = '') {
            try {
                this.dialogBoxType = type;
                const response = await axios.post('/documents/getSharePointAccessToken');
                this.sharepointAccessToken = response.data.data.access_token
                this.path = ''
                await this.fetchAllDocumentsFromSharePoint(this.sharepointAccessToken);
                this.sharepointDataDialog = true;
            } catch (error) {
                this.$notify.error({
                    title: "Error",
                    message: "An Error Occurred during the Authentication"
                })
            }
        },
        async fetchAllDocumentsFromSharePoint(accessToken, path = '') {
            try {
                this.sharepointData = [];
                let apiUrl = `${this.applicationData.app_data.siteUrl}/sites/${this.applicationData.app_data.siteName}/_api/Web/GetFolderByServerRelativePath(decodedurl='/sites/${this.applicationData.app_data.siteName}/Shared%20Documents')?$expand=Folders,Files`;
                if (path) {
                    apiUrl = `${this.applicationData.app_data.siteUrl}/sites/${this.applicationData.app_data.siteName}/_api/Web/GetFolderByServerRelativePath(decodedurl='/sites/${this.applicationData.app_data.siteName}/Shared%20Documents${path}')?$expand=Folders,Files`;
                }
                const response = await axios.get(apiUrl, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Accept': 'application/json',
                    },
                });
                const folderData = response.data;
                const folders = folderData.Folders;
                folders.forEach((folder) => {
                    if (folder.Name !== "Forms") {
                        const document = {
                            file: folder.Name,
                            lastModified: folder.TimeLastModified,
                            fileUrl: folder.ServerRelativeUrl,
                            type: 'folder',
                        };
                        this.sharepointData.push(document);
                    }
                });
            }
            catch (err) {
                this.$notify.error({
                            title: "Error",
                            message: "Error Fetching documents"
                        })
            }
        },
        handleSharepointSizeChange(newSize) {
            this.dialogTablePageSize = newSize;
            this.dialogTableCurrentPage = 1; // Reset current page to 1
        },
        handleCurrentPageChange(newPage) {
            this.dialogTableCurrentPage = newPage;
        },
        handlePath(folderName) {
            this.path = `${this.path}/${folderName}`;
            this.fetchAllDocumentsFromSharePoint(this.sharepointAccessToken, this.path)
        },
        handleBreadcrumbClick(folder) {
            const breadcrumbIndex = this.pathArray.indexOf(folder);
            if (breadcrumbIndex !== -1) {
                this.path = this.pathArray.slice(0, breadcrumbIndex + 1).join('/');
                this.fetchAllDocumentsFromSharePoint(this.sharepointAccessToken, this.path);
            } else if (folder === '') {
                this.path = '';
                this.fetchAllDocumentsFromSharePoint(this.sharepointAccessToken);
            } else {
                // Navigate backward
                const lastIndex = this.path.lastIndexOf('/');
                if (lastIndex !== -1) {
                    this.path = this.path.substr(0, lastIndex);
                    this.fetchAllDocumentsFromSharePoint(this.sharepointAccessToken, this.path);
                }
            }
        },
        selectCurrentFolder() {
            switch (this.dialogBoxType) {
                case "automaticDownload":
                    this.applicationData.app_settings.auto_download_folder_path = this.path;
                    break;
                case "defaultDownload":
                    this.applicationData.app_settings.default_folder_path = this.path;
                    break;
            }
            this.sharepointDataDialog = false;
        },
        async getApplicationData() {
            await this.$store.dispatch(
                "settings/FetchSharePointData"
            );
            if (this.getSharepointData) {
                this.applicationData = this.getSharepointData
            }
            this.loading = false;
        }
    },
    computed: {
        ...mapGetters("auth", [
            "getAuthenticatedUser",
            "getWorkspaceList",
            "getAuthenticationDetails",
            "getUserType",
            "getActiveWorkspace",
        ]),
        ...mapGetters("settings", [
            "getSharepointData"
        ]),
        validateSiteUrl() {
            // Regular expression pattern to match the desired format
            const pattern = /^https?:\/\/[\w.-]+(\.[\w.-]+)+[\S]*$/;

            return pattern.test(this.SharePointFormData.siteUrl);
        },
        validateClientId() {
            const pattern = /^[A-Za-z0-9_-]{1,}$/;
            return pattern.test(this.SharePointFormData.clientId);
        },
        pagedSharepointData() {
            const startIndex = (this.dialogTableCurrentPage - 1) * this.dialogTablePageSize;
            const endIndex = startIndex + this.dialogTablePageSize;
            return this.filteredSharepointData.slice(startIndex, endIndex);
        },
        pathArray() {
            return this.path.split('/');
        },
        filteredSharepointData() {
            if (this.searchQuery) {
                const searchTerm = this.searchQuery.toLowerCase();
                return this.sharepointData.filter(item => item.file.toLowerCase().includes(searchTerm));
            } else {
                return this.sharepointData;
            }
        }
    },
    async mounted() {
        //fetch app integrations (Sharepoint with company id)
        this.getApplicationData();
    },
}
</script>

<style scoped>
.sidemenu {
    max-height: 510px;
    overflow-y: scroll;
    overflow-x: scroll;
    border-top: 1px ridge;
    border-left: 1px ridge;
    border-bottom: 1px ridge;
    margin-right: 5px;
}

.sidemenu::-webkit-scrollbar {
    width: 0.7em;
}

.sidemenu::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    background: #e1e1e1;
    -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}

.sidemenu::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #F5F5F5;
    background-color: #F5F5F5;

}

.el-form-item {
    margin-bottom: 0px;
}

.input-container,
.app-creation,
.app-permissions {
    padding-bottom: 10px;
    border-bottom: 1px dotted #E4E7ED;
}

.input-label {
    margin-bottom: 5px;
}

.validation-error {
    color: red;
    font-size: 12px;
    margin-bottom: -10px;
}

.navigation-link {
    margin: 10px 0px;
    font-size: 12px;
    background-color: #ECF5FF;
    color: #409EFF;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

a:hover {
    cursor: pointer;
}

ol {
    margin-bottom: 10px;
}

.xml-code-block {
    background-color: #F2F6FC;
    font-size: 12px;
}

.xml-code-block a {
    background-color: #ECF5FF;
    color: #409EFF;
}

a {
    color: #409EFF;
}

.pagination-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.breadcrumb-item {
    cursor: pointer;
    color: #409eff;
}

.breadcrumb-separator {
    margin: 0 5px;
    color: #909399;
}

.folder-column {
    cursor: pointer;
}

.main-label {
    margin-bottom: 10px;
}
</style>